import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header/header'

import Footer from './footer/footer'
import SideBar from './sidebar/sidebar'
import MobileMenu from './menus/mobileMenu'

const Layout = ({ children, bgColor }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="header-position-left">
        <Header siteTitle={data.site.siteMetadata.title} />
        <MobileMenu />
        <div className="">
          <SideBar />
        </div>
        <div
          id="site-content"
          className="site-content"
          style={{ backgroundColor: bgColor ? '#f4f4f9' : '' }}
        >
          <div id="content-body" className="content-body">
            <div className="content-body-inner wrap">
              <div className="main-content" id="main-content">
                <div className="main-content-inner">
                  <div className="content">{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
