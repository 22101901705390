import React from 'react'
import { Link } from 'gatsby'

const PrimaryButton = ({ title, style, to, submit }) => {
  return (
    <>
      {submit
        ? (
        <button type="submit" style={style} className="button primary">
          {title}
        </button>
          )
        : (
        <Link to={to} style={style} className="button primary">
          {title}
        </Link>
          )}
    </>
  )
}

export default PrimaryButton
