import React, { Component } from "react"
import $ from "jquery"
import { graphql, StaticQuery, Link } from "gatsby"

import { ArrowDownCircleOutline, ChevronDown } from "react-ionicons"
import { StaticImage } from "gatsby-plugin-image"

class MobileMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
    }
  }

  componentDidMount() {
    const mobileMenuSticky = $(".mobile-menu.fixed-menu")
    const toggleButton = $(".toggle-button")
    const menuCanvas = $(".mobile-menu-canvas")
    const iconRound = $(".icon-round")

    $(window).scroll(function () {
      if ($(this).scrollTop() > 60) {
        mobileMenuSticky.addClass("active")
      } else {
        mobileMenuSticky.removeClass("active")
      }
    })

    $(document).ready(function () {
      toggleButton.on("click", function () {
        toggleButton.toggleClass("active")
        menuCanvas.toggleClass("active")
      })

      iconRound.on("click", function (e) {
        // deactivate others
        const currentElem = e.currentTarget

        const submenuItems = $("#mobile-menu-items .mobile-submenu")
        $(submenuItems).each(function (index, item) {
          const parentMenuItem = $(item).parent(".mobile-menu-item")
          const iconElem = $(parentMenuItem).find(".icon-round")
          if (iconElem.length > 0 && currentElem === iconElem[0]) {
            // console.log("Same elem: ", iconElem)
            if ($(parentMenuItem).find(".mobile-submenu").hasClass("active")) {
              $(parentMenuItem).find(".mobile-submenu").removeClass("active")
              $(parentMenuItem).find(".icon-round").removeClass("active")
            } else {
              $(parentMenuItem).find(".mobile-submenu").addClass("active")
              $(parentMenuItem).find(".icon-round").addClass("active")
            }
          } else {
            // console.log("Different elem: ")
            $(parentMenuItem).find(".mobile-submenu").removeClass("active")
            $(parentMenuItem).find(".icon-round").removeClass("active")
          }
        })
      })
    })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query mobileMenuQuery {
            logo: file(relativePath: { eq: "logo-mobile-menu.png" }) {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <div className="mobile-menu-wrapper">
              <div id="mobile-menu" className="mobile-menu d-block d-md-none">
                <div className="mobile-menu-inner">
                  <div className="mobile-menu-content">
                    <div className="mobile-menu-brand">
                      <a href="/">
                        <img
                          src={data.logo.childImageSharp.fluid.src}
                          alt="explorelogy logo"
                        />
                      </a>
                    </div>
                    <nav style={{ flexGrow: 1 }}></nav>
                    <div className="extras">
                      <div className="header-info-text">
                        Copyright © 2020 Explorelogy
                      </div>
                    </div>
                    <span className="off-canvas-toggle toggle-button">
                      <StaticImage
                        placeholder="blurred"
                        className="toggle-icon"
                        src={"./menu-outline.svg"}
                        alt="explorelogy logo"
                      />
                    </span>
                  </div>
                </div>
                <div className="mobile-menu-canvas">
                  <div className="toggle-wrapper">
                    <div>
                      <input
                        placeholder="Search"
                        className="search-input"
                        type="text"
                      />
                    </div>
                    <div className="toggle-button">
                      <StaticImage
                        placeholder="blurred"
                        className="toggle-icon round-colored"
                        src={"./close-outline.svg"}
                        alt="explorelogy logo"
                      />
                    </div>
                  </div>
                  <div className="mobile-menu-items-wrapper">
                    <ul id="mobile-menu-items" className="mobile-menu-items">
                      {menu_data.map((item, index) => {
                        return (
                          <NavItem
                            key={index}
                            title={item.title}
                            to={item.to}
                            subMenuItems={item.submenuItems}
                          />
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                id="mobile-menu"
                className={
                  "mobile-menu fixed-menu d-block d-md-none" +
                  `${this.props.fixed ? " fixed-menu " : ""}`
                }
              >
                <div className="mobile-menu-inner">
                  <div className="mobile-menu-content">
                    <div className="mobile-menu-brand">
                      <a href="/">
                        <img
                          src={data.logo.childImageSharp.fluid.src}
                          alt="exploreolgy logo"
                        />
                      </a>
                    </div>
                    <nav style={{ flexGrow: 1 }}></nav>
                    <div className="extras">
                      <div className="header-info-text">
                        Copyright © 2020 Explorelogy
                      </div>
                    </div>
                    <span className="off-canvas-toggle toggle-button">
                      <StaticImage
                        placeholder="blurred"
                        className="toggle-icon"
                        src={"./menu-outline.svg"}
                        alt="explorelogy logo"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      ></StaticQuery>
    )
  }
}

const NavItem = ({ subMenuItems, title, to }) => {
  return (
    <li className="mobile-menu-item has-icon">
      <div className="mobilemenu-title">
        <Link to={to}>{title}</Link>
        {subMenuItems ? (
          <div className="icon-round">
            <ChevronDown
              color="#fff"
              style={{ fill: "#fff", position: "absolute" }}
            />
          </div>
        ) : null}
      </div>
      {subMenuItems ? (
        <div className="mobile-submenu">
          <ul>
            {subMenuItems &&
              subMenuItems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.to}>{item.title}</Link>
                  </li>
                )
              })}
          </ul>
        </div>
      ) : null}
    </li>
  )
}

const menu_data = [
  {
    title: "Home",
    to: "/",
  },
  {
    title: "Company",
    to: "/about",
    submenuItems: [
      {
        title: "About",
        to: "/about",
      },
      {
        title: "FAQ",
        to: "/faq",
      },
      {
        title: "Careers",
        to: "/careers",
      },
    ],
  },
  {
    title: "Blog",
    to: "/blog",
  },
  {
    title: "Services",
    to: "/services",
    submenuItems: [
      {
        title: "Business Concept Development",
        to: "/services/business-concept-development",
      },
      {
        title: "Web Development",
        to: "/services/web-development",
      },
      {
        title: "Managed Cloud Services",
        to: "/services/managed-cloud-service",
      },
      {
        title: "Managed IT Services",
        to: "/services/managed-it-service",
      },
      {
        title: "Cyber Security",
        to: "/services/cyber-security",
      },
      {
        title: "IT Consultancy",
        to: "/services/it-consultancy",
      },
    ],
  },
  {
    title: "Careers",
    to: "/careers",
  },
  {
    title: "Products",
    to: "/products",
  },
  {
    title: "Contact",
    to: "/contact",
  },
]

export default MobileMenu
