import React from 'react'
import { Link } from 'gatsby'

const AccentButton = ({ title, style, className, to }) => {
  return (
    <Link to={to} style={style} className={`button accent + ${className}`}>
      {title}
    </Link>
  )
}

export default AccentButton
