import React from 'react'
import PrimaryButton from '../buttons/primary_button'
import AccentButton from '../buttons/accent_button'
import { Link, useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query Footer {
      bgImage: file(relativePath: { eq: "footer-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <footer>
      <div className="site-footer" id="site-footer">
        <BackgroundImage
          fluid={data.bgImage.childImageSharp.fluid}
          Tag="div"
          className="content-bottom-widgets"
        >
          <div className="content-bottom-inner wrap">
            <div className="content-bottom-aside-wrap">
              <div className="col-md-6">
                <div
                  id="text-6"
                  className="widget-odd widget-first widget-1 widget widget_text"
                >
                  <h4 className="widget-title">Need IT assistance? </h4>
                  <div className="textwidget">
                    <h4 className="no-margin-top">
                      Get in touch with us if you have more questions or if you
                      want to get started right away.
                    </h4>
                    <p>
                      Call us on&nbsp;
                      <a href="tel:94112769666" className="link">
                        + (9411) 2769 666
                      </a>
                      &nbsp;
                      <br />
                      email us at &nbsp;{' '}
                      <a className="link" href="mailto:hello@explorelogy.lk">
                        hello@explorelogy.lk
                      </a>
                      <br />
                      or fill out the following form to start the conversation.
                    </p>
                  </div>
                </div>
                <div className="button-group">
                  <PrimaryButton to="/services" title="Find our services" />
                  <AccentButton to="/products" title="Find our products" />
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </BackgroundImage>
        <div className="footer-widgets">
          <div className="footer-widget-inner wrap">
            <div className="row">
              <div className="col-md-3">
                <div className="widget-odd widget-last widget-first widget-1 widget widget_text">
                  <div className="textwidget">
                    <p>
                      Address: Explorelogy Pvt Ltd, 51, 5th Lane, Colombo 00300
                    </p>
                    <p>
                      Phone: <a href="tel:+94112769666"> + (9411) 2769 666</a>
                      <br />
                      Mail: <Link to="/contact"> hello@explorelogy.lk</Link>
                    </p>
                    <p>Copyright © 2020 Explorelogy</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <ul className=" col-md-3 col-sm-6 col-6 footer-submenu">
                    <li className="submenu-title">IT Services</li>
                    <li>
                      <Link to="/services/">Business Concept Development</Link>
                    </li>
                    <li>
                      <Link to="/services/">Web Development</Link>
                    </li>
                    <li>
                      <Link to="/services/">Cloud Services</Link>
                    </li>
                    <li>
                      <Link to="/services/">IT Consultancy</Link>
                    </li>
                  </ul>
                  <ul className=" col-md-3 col-sm-6 col-6  footer-submenu">
                    <li className="submenu-title">Company</li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/about#why-choose-us">Why Choose Us</Link>
                    </li>
                    <li>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <li>
                      <Link to="/about#history">History</Link>
                    </li>
                  </ul>
                  <ul className=" col-md-3 col-sm-6 col-6  footer-submenu">
                    <li className="submenu-title">Support</li>

                    <li>
                      <Link to="/blog">IT Blog</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <a href="/">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/LICENSE">T&C</a>
                    </li>
                  </ul>
                  <ul className=" col-md-3 col-sm-6 col-6  footer-submenu">
                    <li className="submenu-title">Follow Us</li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/explorelogy"
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/company/explorelogy/"
                      >
                        Linkedin
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="subscribe-form">
                  <div className="subscribe-style">
                    <p>
                      Learn about IT Solutions with the monthly our newsletter
                    </p>
                    <input
                      type="email"
                      name="EMAIL"
                      placeholder="Enter your Email"
                      required=""
                    ></input>
                    <input type="submit" value="SUBSCRIBE"></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
