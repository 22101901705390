import React, { useState } from "react"
import AccentButton from "../buttons/accent_button"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { motion } from "framer-motion"

// icons
import {
  HomeOutline,
  GlobeOutline,
  CogOutline,
  BookOutline,
  LogoBuffer,
  BriefcaseOutline,
  LogoFacebook,
  LogoLinkedin,
} from "react-ionicons"

const SideBar = () => {
  const [active, setActive] = useState(false)

  const variantsIcon = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
  }

  const variantsLogo = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.1,
        duration: 0.3,
      },
    },
    closed: {
      opacity: 0,
      x: "100%",
      transition: {
        delay: 0,
      },
    },
  }

  return (
    <div
      onMouseEnter={() => {
        setActive(true)
      }}
      onMouseLeave={() => {
        setActive(false)
      }}
      className={`${
        active ? "active" : ""
      } site-header site-header-classic header-brand-left header-shadow d-none d-md-block`}
    >
      <div className="site-header-inner wrap">
        <div className="header-content">
          <div className="header-brand">
            <Link className="logo-wrapper" to="/">
              <motion.img
                className="logo-icon"
                initial={{
                  opacity: 1,
                }}
                transition={{
                  duration: 0.3,
                }}
                style={{ width: 40, height: 40 }}
                animate={!active ? "open" : "closed"}
                variants={variantsIcon}
                src={"/logo-icon.svg"}
                alt="explorelogy logo"
              />
              <motion.img
                className="logo"
                initial={{
                  opacity: 0,
                }}
                style={{ width: "80%" }}
                animate={active ? "open" : "closed"}
                variants={variantsLogo}
                src={"/logo.svg"}
                alt="explorelogy logo"
              />
            </Link>
          </div>
          <div className="navigator">
            <div className="nav-info">
              <div className="aligncenter">
                <p>
                  <AccentButton
                    to="/contact"
                    className="small"
                    title="CONTACT US"
                  />
                </p>
                <div>Have any questions?</div>
                <Link to="/contact">hello@explorelogy.lk</Link>
              </div>
            </div>
            <Navigator active={active} />
          </div>
          <div className="extras">
            <div className="header-info-text">Copyright © 2020 Explorelogy</div>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/explorelogy"
                rel="noreferrer"
                target="_blank"
              >
                <LogoFacebook className="social-icon" />
              </a>

              <a
                rel="noreferrer"
                href="https://www.linkedin.com/company/explorelogy/"
                target="_blank"
              >
                <LogoLinkedin className="social-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const iconstyle = {
  width: 25,
  height: "auto",
  flexShrink: 0,
  textAlign: "center",
  marginRight: 15,
}

const Navigator = ({ active }) => {
  const menuItemVariant = {
    open: {
      opacity: 1,
      translateX: 0,
    },
    closed: {
      opacity: 0,
      translateX: "100%",
    },
  }
  return (
    <ul id="menu-main-menu" className="menu menu-primary">
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home">
        <NavLink to="/">
          <HomeOutline className="menu-icon" style={iconstyle} />
          <motion.span
            animate={active ? "open" : "closed"}
            variants={menuItemVariant}
            transition={{
              delay: 0.05,
            }}
          >
            Home
          </motion.span>
        </NavLink>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-has-toggle">
        <NavLink to="/about">
          <GlobeOutline className="menu-icon" style={iconstyle} />
          <motion.span
            animate={active ? "open" : "closed"}
            variants={menuItemVariant}
            transition={{
              delay: 0.1,
            }}
          >
            Company
          </motion.span>
        </NavLink>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-has-toggle">
        <NavLink to="/services">
          <CogOutline className="menu-icon" style={iconstyle} />
          <motion.span
            animate={active ? "open" : "closed"}
            variants={menuItemVariant}
            transition={{
              delay: 0.15,
            }}
          >
            Services
          </motion.span>
        </NavLink>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home">
        <NavLink to="/blog">
          <BookOutline className="menu-icon" style={iconstyle} />
          <motion.span
            animate={active ? "open" : "closed"}
            variants={menuItemVariant}
            transition={{
              delay: 0.2,
            }}
          >
            Blog
          </motion.span>
        </NavLink>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home">
        <NavLink to="/products">
          <LogoBuffer className="menu-icon" style={iconstyle} />
          <motion.span
            animate={active ? "open" : "closed"}
            variants={menuItemVariant}
            transition={{
              delay: 0.25,
            }}
          >
            Products
          </motion.span>
        </NavLink>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home">
        <NavLink to="/careers">
          <BriefcaseOutline className="menu-icon" style={iconstyle} />
          <motion.span
            animate={active ? "open" : "closed"}
            variants={menuItemVariant}
            transition={{
              delay: 0.3,
            }}
          >
            Careers
          </motion.span>
        </NavLink>
      </li>
    </ul>
  )
}

const NavLink = ({ children, to }) => {
  const pathname = useLocation().pathname

  const urlSegments = pathname.split("/")
  const toSegments = to.split("/")

  const isActive = urlSegments[1] === toSegments[1]
  const className = isActive ? "active" : ""

  return (
    <Link className={className} to={to}>
      {children}
    </Link>
  )
}

export default SideBar
