import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { useLocation } from '@reach/router'

const Header = ({ siteTitle }) => (
  <header>
    <TopBar />
    <SiteHeader />
  </header>
)

const TopBar = () => (
  <div className="site-topbar" id="site-topbar">
    <div className="wrap">
      <div className="site-topbar-inner">
        <div className="topbar-text">
          <Link to="/careers" className="dot">
            Now Hiring:
          </Link>
          &nbsp; Are you a driven and motivated Full Stack Developer?
        </div>
        <div className="topbar-menu d-none d-md-block">
          <ul id="menu-top-menu" className="menu menu-top">
            <NavLink to="/about" title="About" />
            <NavLink to="/services" title="Services" />
            <NavLink to="/blog" title="Blog" />
            <NavLink to="/products" title="Products" />
            <NavLink to="/careers" title="Careers" />
            <NavLink to="/contact" title="Contact" />
          </ul>
        </div>
      </div>
    </div>
  </div>
)

const SiteHeader = () => (
  <div
    id="site-header"
    className="site-header site-header-classic header-brand-left header-shadow"
  ></div>
)

const NavLink = ({ to, title }) => {
  const pathname = useLocation().pathname
  const currentRoute = to

  // console.log(pathname, currentRoute)

  return (
    <li className="menu-item">
      <Link className={pathname === currentRoute ? 'active ' : ''} to={to}>
        {title}
      </Link>
    </li>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export default Header
